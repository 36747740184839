
import { ref } from 'vue'
import { myDoneHomeworks } from '@/api/user/student'
import MomaiCourseListWithChildren from '@/components/MomaiCourseListWithChildren.vue'
import { CourseSeriesWithCourses, CourseWithHomewors } from '@/api/user/student/types'
export default {
  components: { MomaiCourseListWithChildren },

  setup: () => {
    const list = ref<CourseSeriesWithCourses<CourseWithHomewors>[]>([])
    myDoneHomeworks().then(r => {
      r.forEach(cs => {
        cs.courseInfos.forEach(c => {
          c.needShowDot = c.homeworks.some(h => h.homework.unread === '0' && h.homework.status === '1')
        })
        cs.needShowDot = cs.courseInfos.some(c => c.needShowDot)
      })
      list.value = r
    })
    const expandedCourseSeries = ref(0)

    return {
      expandedCourseSeries,
      list
    }
  }
}
