
import { CourseSeriesWithCourses, CourseWithHomewors } from '@/api/user/student/types'
import { defineComponent, PropType, watch, ref, computed } from 'vue'

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<CourseSeriesWithCourses<CourseWithHomewors>[]>,
      required: true
    },
    expandedValue: {
      type: [Number, String],
      required: true
    }
  },

  setup: (props, { emit }) => {
    // 用于v-model绑定
    const expandedCourseSeries = ref(props.expandedValue)
    watch(() => props.expandedValue, newValue => {
      expandedCourseSeries.value = newValue
    })
    watch(expandedCourseSeries, newValue => {
      emit('update:expandedValue', newValue)
    })
    // 用于v-model绑定

    return {
      expandedCourseSeries,
      listWithNotNullCourses: computed(() => props.list.filter(item => item.course))
    }
  }
})
